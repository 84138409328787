//
//
//
//

import { v4 as uuidv4 } from 'uuid';
import pageMixin from '$mixins/page-mixin.js';
import StepLayout from '../cuota-personalizada/steps/step-layout.vue';
import { createExistingLead } from '$network/api.js';
import { fetchStepsData } from '../cuota-personalizada/lib/api.js';

export default {
  name: 'LeadFromAutomatic',
  components: {
    StepLayout
  },
  mixins: [pageMixin],
  data: () => ({}),
  async created() {
    const channel = 'manual';

    window.localStorage.setItem('channel', channel);

    const { id_lead } = this.$route.query;

    const uid = uuidv4();

    const lead = await createExistingLead(channel, id_lead, uid);

    window.postMessage('cosmos-close-modal', '*');

    this.$router.push({
      name: 'Cuota personalizada',
      params: {
        uid
      }
    });
  }
};
